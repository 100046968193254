import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import PropTypes from 'prop-types';

const EventCardNew = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Content = styled.a`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 120px;
  gap: 20px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorsV2.neutral20};
  margin-top: 24px;
`;
const LeftContent = styled.div`
  .news-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &:hover {
      text-decoration: underline;
    }
  }
  .news-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
const RightContent = styled.div`
  display: grid;
  align-items: center;
  img {
    width: 100%;
    height: 84px;
    border-radius: 16px;
  }
`;

export const DolEventCardNewV2 = props => {
  const { hrefUrl, coverUrl, name, description, alt, idx } = props;

  return (
    <EventCardNew key={idx}>
      <Content href={hrefUrl}>
        <LeftContent>
          <Typography
            className="news-name"
            variant="semi-bold/18-24"
            color={colorsV2.black100}
            v3
          >
            {name}
          </Typography>
          <Typography
            className="news-description"
            variant="regular/16-24"
            color={colorsV2.black80}
            v3
          >
            {description}
          </Typography>
        </LeftContent>
        <RightContent>
          <LazyImage
            imgProps={{
              alt: `${alt || ''}`
            }}
            src={coverUrl}
          />
        </RightContent>
      </Content>
      <Divider className={`divider-${idx}`} />
    </EventCardNew>
  );
};

DolEventCardNewV2.propTypes = {
  hrefUrl: PropTypes.string,
  coverUrl: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  alt: PropTypes.string,
  idx: PropTypes.number
};
