import * as React from 'react';
import { useInfiniteQuery } from 'react-query';

import MainLayout from 'components/Layout/MainLayout';
import MySEO from 'shared/MySEO';

import { apiCacheKeys } from '../apis/api.urls';
import { getListedBlogs } from '../service/blogs-service';
import { BLOG_TYPE, SORT_BY_ORDERING_ASC } from '../constant';
import { ButtonLoadMore } from '../components/ButtonLoadMore/ButtonLoadMore';
import { DolNewsBanner } from '../components/DolNews/DolNewsBanner';
import { DolNewsMainContent } from '../components/DolNews/DolNewsMainContent';
import { DolNewsSection } from '../components/DolNews/DolNewsSection';
import { DolNewsFeatureList } from '../components/DolNews/DolNewsFeatureList';
import { DolNewsLatestList } from '../components/DolNews/DolNewsLatestList';
import { FooterEndSection } from '../components/FooterEndSection/FooterEndSection';
import { graphql } from 'gatsby';
import { HeadlineIcon } from 'components/HeadlineIcon';
import { Typography } from 'components/DesignSystemV2/Typography';

const listingParams = {
  blogType: BLOG_TYPE.DOL_NEWS,
  size: 6,
  sort: SORT_BY_ORDERING_ASC
};

const DolNewsPageBase = props => {
  const { location, allLinearThinkingNews, allLinearThinkingLatestNews } =
    props;
  const featureData = {
    content: allLinearThinkingNews.nodes
  };
  // const { data: featureData } = useQuery(
  //   [apiCacheKeys.publicService.GET_LISTED_IELTS_BLOGS_LANDING, featureParams],
  //   () => getListedBlogs(featureParams)
  // );
  //
  const excludedIds = featureData?.content?.map(({ id }) => id) || [];

  const {
    data: listingData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(
    [apiCacheKeys.publicService.GET_LISTED_IELTS_BLOGS_LANDING, listingParams],
    ({ pageParam = 0 }) =>
      getListedBlogs({
        ...listingParams,
        page: pageParam,
        excludedId: excludedIds
      }),
    {
      initialData: {
        pageParams: [undefined],
        pages: [
          {
            content: allLinearThinkingLatestNews?.nodes,
            pageable: {
              sort: {
                sorted: true,
                unsorted: false,
                empty: false
              },
              pageNumber: 0,
              pageSize: 6,
              offset: 0,
              paged: true,
              unpaged: false
            },
            last: false,
            sort: {
              sorted: true,
              unsorted: false,
              empty: false
            },
            first: true,
            size: 6,
            number: 0,
            empty: false
          }
        ]
      },
      enabled: Boolean(excludedIds?.length),
      getNextPageParam: lastPage => {
        const { number, last = false } = lastPage;
        if (last) {
          return undefined;
        }
        if (isNaN(number)) {
          return 0;
        }
        return number + 1;
      }
    }
  );

  return (
    <MainLayout showFooterRegister hideSideCTA>
      <MySEO title="Tin tức về DOL English" slug={location.pathname} />
      <DolNewsBanner
        title={`<span class="highlight">Tin tức & sự kiện</span> về DOL`}
        description="Chuyên mục chứa những tin tức mới nhất về các hoạt động và sự kiện mà DOL tham dự. Cũng như các sự kiện mà DOL vinh dự được tham gia với tư cách là nhà tài trợ, đối tác và bảo trợ truyền thông."
      />
      <DolNewsMainContent
        sectionFeature={
          <DolNewsSection
            title={
              <HeadlineIcon
                iconHandle="x2dlqr50RdOvP6ZLm83K"
                className="section-title"
              >
                <Typography
                  variant="bold/24-32"
                  tabletVariant="bold/32-40"
                  desktopVariant="bold/40-48"
                  v3
                  as="h2"
                >
                  Sự kiện tiêu biểu của DOL
                </Typography>
              </HeadlineIcon>
            }
            content={<DolNewsFeatureList data={featureData?.content} />}
          />
        }
        sectionLatest={
          <DolNewsSection
            title={<h2 className="section-heading">Tin tức khác</h2>}
            content={
              <DolNewsLatestList
                data={listingData?.pages.flatMap(p => p.content)}
                loadMoreButton={
                  hasNextPage ? (
                    <ButtonLoadMore
                      text="Xem thêm tin tức"
                      loading={isFetchingNextPage}
                      onClick={fetchNextPage}
                    />
                  ) : (
                    <FooterEndSection message="Bạn đã xem hết tin tức" />
                  )
                }
              />
            }
          />
        }
      />
    </MainLayout>
  );
};

const DolNewsPage = ({ location, data }) => {
  return <DolNewsPageBase location={location} {...data} />;
};

export default DolNewsPage;

export const pageQuery = graphql`
  query LinearThinkingNewsQuery {
    allLinearThinkingNews {
      nodes {
        author
        blogId
        blogType
        description
        id
        listingStatus
        name
        numOfViews
        status
        tableOfContent
        cover {
          path
          url
          size
          caption
          alt
        }
        page {
          url
        }
        title
        tocTitle
      }
    }
    allLinearThinkingLatestNews {
      nodes {
        author
        blogId
        blogType
        description
        id
        listingStatus
        name
        numOfViews
        status
        tableOfContent
        cover {
          path
          url
          size
          caption
          alt
        }
        title
        tocTitle
      }
    }
  }
`;
